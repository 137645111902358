@mixin respond ($breakpoint) {

    @if $breakpoint == small-phone {
        @media (max-width: 400px) {
            @content; } }

    @if $breakpoint == phone {
        @media (max-width: 768px) {
            @content; } }

    @if $breakpoint == tab-port {
        @media (max-width: 992px) {
            @content; } }

    @if $breakpoint == tab-land {
        @media (max-width: 1200px) {
            @content; } }

    @if $breakpoint == laptop {
        @media (max-width: 1440px) {
            @content; } }

    @if $breakpoint == big-desktop {
        @media (min-width: 1921px) {
            @content; } }

    @if $breakpoint == animation {
        @media (min-width: 1200px) {
            @content; } } }


@mixin position-center () {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }


@mixin parallax-section () {
    position: relative;
    overflow: hidden;

    .parallax-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 130%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0; } }


@mixin icon-elements-list {
    &__wrap {
        font-size: 0; }

    &__col {
        display: inline-block;
        float: none;
        vertical-align: top; }

    &__item {
        margin-bottom: 4rem; }

    &__title-block {
        position: relative;
        margin-bottom: 2rem;
        // padding-left: 6.5rem
        padding-top: 1.1rem; }

    &__icon {
        width: 5rem;
        height: 5rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 0 auto 2rem;
        display: block; }

    &__title {
        margin: 0;
        font-size: 1.8rem;
        text-align: center;
        line-height: 1.5;

        @include respond (laptop) {
 } }            // font-size: 2.1rem

    &__description {
        text-align: center;
        // padding-right: 4rem
        * {
            color: #9e9e9e;
            font-size: 1.4rem; }

        a,
        a:link,
        a:visited {
            color: inherit;

            &:hover,
            &:active {
                text-decoration: none;
                color: $color-brand; } } } }
