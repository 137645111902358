.fade-in-out {
    transition: all .7s;
    opacity: 0;
    transform: translateX(-50px);

    &.fade-in {
        opacity: 1;
        transform: translateX(0); }

    @media (max-width: 992px) {
        transform: translateX(0); } }


@keyframes sidebarElements {
    0% {
        transform: translateX(-40px);
        opacity: 0; }

    100% {
        opacity: 1;
        transform: translateX(0); } }

@keyframes leftSwipe {
    0% {
        transform: translateX(40px);
        opacity: 0; }

    100% {
        opacity: 1;
        transform: translateX(0); } }


@keyframes homeElements {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }


@keyframes homeLogo {
    0% {
        transform: translate(-90%, -55%);
        opacity: 0; }

    // 25%
    //     opacity: 1
    //
    // 40%
    //     opacity: 1
    //     transform: translate(-90%, -55%)

    100% {
        opacity: 1;
        transform: translate(-100%, -55%); } }
