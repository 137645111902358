$bar-width: 50px;
$bar-height: 5px;
$bar-spacing: 15px;

.hamburger-menu {
    // position: fixed
    position: absolute;
    top: 2rem;
    left: 2rem;
    // right: 0
    // bottom: 0
    margin: auto;
    // width: $bar-width
    // height: $bar-height + $bar-spacing*2
    height: 52px;
    width: 66px;
    cursor: pointer;
    z-index: 100;
    display: none;
    padding: 1rem;
    // background-color: rgba(#fff, .9)

    @include respond (phone) {
        display: block; } }


.bar,
.bar:after,
.bar:before {
    width: $bar-width;
    height: $bar-height; }

.bar {
    position: relative;
    transform: translateY($bar-spacing);
    background: rgba(#000, 1);
    transition: all 0ms 300ms;

    &.animate {
        background: rgba(255, 255, 255, 0);
 } }        // position: fixed


.bar:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    // background: rgba(255, 255, 255, 1)
    background: rgba(#000, 1);
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1); }


.bar:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: rgba(#000, 1);
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1); }


.bar.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1); }


.bar.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1); }

// .nav-bg
//     height: 6rem
//     width: 6rem
//     border-radius: 50%
//     position: fixed
//     top: 3.5rem
//     right: 4.5rem
//     background: linear-gradient(bottom, $color-brand, $color-brand-dark)
//     z-index: 1000
//     transition: all .8s cubic-bezier(0.86, 0, 0.07, 1)
//     visibility: hidden
//
//     &.active
//         visibility: visible
//         transform: scale(80)
//
//
// .burger-menu
//     background-color: #fff
//     height: 7rem
//     width: 7rem
//     position: fixed
//     top: 3rem
//     right: 4rem
//     border-radius: 50%
//     z-index: 2000
//     box-shadow: 0 1rem 2rem rgba(0,0,0,.3)
//     text-align: center
//     outline: none
//
//     display: none
//     @include respond (tab-port)
//         display: block
//
//     &__icon
//         position: relative
//         margin-top: 3.4rem
//
//         &,
//         &::before,
//         &::after
//             width: 3rem
//             height: 2px
//             background-color: #000
//             display: inline-block
//
//         &::before,
//         &::after
//             content: ""
//             position: absolute
//             left: 0
//             transition: all .2s
//
//         &::before
//             top: -.8rem
//
//         &::after
//             top: .8rem
//
//     &:focus
//         outline: none
//
//     &:hover &__icon::before
//         top: -1rem
//
//     &:hover &__icon::after
//         top: 1rem
//
//
//
//     &.opened &__icon
//         background-color: transparent
//
//     &.opened &__icon::before
//         top: 0
//         transform: rotate(135deg)
//
//     &.opened &__icon::after
//         top: 0
//         transform: rotate(-135deg)
//
//
// .mobile-nav
//     min-height: 100vh
//     width: 100%
//     position: fixed
//     top: 0
//     right: 0
//     z-index: 1500
//     opacity: 0
//     transform: translateX(-10rem)
//     transition: opacity .8s, transform .5s
//     visibility: hidden
//     animation-fill-mode: backwards
//     overflow: auto
//     padding-bottom: 5rem
//
//     &.active
//         visibility: visible
//         transform: translateX(0)
//         opacity: 1
//
//     &__wrap
//         position: absolute
//         top: 50%
//         left: 50%
//         transform: translate(-50%, -50%)
//         list-style: none
//         text-align: center
//
//         @include respond(tab-port)
//             // top: 0
//             // left: 0
//             // transform: translate(0)
//             // width: 100%
//
//     &__list
//         &>li
//             margin: 1rem
//             list-style: none
//
//             &>a
//                 &:link,
//                 &:visited
//                     display: inline-block
//                     font-size: 3rem
//                     font-weight: 300
//                     color: #fff
//                     padding: 1rem 2rem
//                     text-decoration: none
//                     text-transform: uppercase
//                     letter-spacing: .1rem
//                     background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%)
//                     background-size: 220%
//                     transition: all .4s
//
//                 &:hover,
//                 &:active,
//                 &:focus
//                     background-position: 100%
//                     color: $color-brand
//                     transform: translateX(1rem)
