@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700&display=swap&subset=cyrillic');


// .section-title
//     font-size: 3.6rem
//     font-weight: 500
//     position: relative
//     margin-bottom: 4rem
//
//     @include respond (laptop)
//         font-size: 3.2rem
