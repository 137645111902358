.row-space {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
/* ==========================================================================
   #BACKGROUND
   @mixin ========================================================================= */
.bg-blue {
    // background: #2c6ed5
    background: $color-blue; }


.bg-red {
    background: #fa4251; }


.bg-gra-01 {
    background: -webkit-gradient(linear, left bottom, left top, from(#fbc2eb), to(#a18cd1));
    background: -webkit-linear-gradient(bottom, #fbc2eb 0%, #a18cd1 100%);
    background: -moz-linear-gradient(bottom, #fbc2eb 0%, #a18cd1 100%);
    background: -o-linear-gradient(bottom, #fbc2eb 0%, #a18cd1 100%);
    background: linear-gradient(to top, #fbc2eb 0%, #a18cd1 100%); }


.bg-gra-02 {
    background: -webkit-gradient(linear, left bottom, right top, from(#fc2c77), to(#6c4079));
    background: -webkit-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
    background: -moz-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
    background: -o-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
    background: linear-gradient(to top right, #fc2c77 0%, #6c4079 100%); }


/* ==========================================================================
   #SPACING
   @mixin ========================================================================= */
.p-t-100 {
    padding-top: 100px; }


.p-t-130 {
    padding-top: 130px; }


.p-t-180 {
    padding-top: 180px; }


.p-t-20 {
    padding-top: 20px; }


.p-t-15 {
    padding-top: 15px; }


.p-t-10 {
    padding-top: 10px; }


.p-t-30 {
    padding-top: 30px; }


.p-b-100 {
    padding-bottom: 100px; }


.m-r-45 {
    margin-right: 45px; }


/* ==========================================================================
   #WRAPPER
   @mixin ========================================================================= */
.wrapper {
    margin: 0 auto; }


.wrapper--w960 {
    max-width: 960px; }


.wrapper--w780 {
    max-width: 780px; }


.wrapper--w680 {
    max-width: 680px; }


/* ==========================================================================
   #BUTTON
   @mixin ========================================================================= */
.btn {
    display: inline-block;
    line-height: 50px;
    padding: 0 50px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;

    &.btn-sm {
        padding: .5rem 1rem;
        font-size: 1.4rem;
        line-height: 1.5;
        border-radius: .2rem;
        border-radius: 4px; } }


.btn--radius {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px; }


.btn--radius-2 {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }


.btn--pill {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px; }


.btn--green {
    background: $color-green; }


.btn--green:hover {
    background: #4dae3c; }


.btn--blue {
    background: #4272d7; }


.btn--blue:hover {
    background: #3868cd; }


.btn--red {
    background: $color-pink; }


.btn--red:hover {
    background: $color-pink; }


/* ==========================================================================
   #DATE PICKER
   @mixin ========================================================================= */
td.active {
    background-color: #2c6ed5; }


input[type="date" i] {
    padding: 14px; }


.table-condensed td, .table-condensed th {
    font-size: 14px;
    font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
    font-weight: 400; }


.daterangepicker td {
    width: 40px;
    height: 30px; }


.daterangepicker {
    border: none;
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    display: none;
    border: 1px solid #e0e0e0;
    margin-top: 5px; }


.daterangepicker::after, .daterangepicker::before {
    display: none; }


.daterangepicker thead tr th {
    padding: 10px 0; }


.daterangepicker .table-condensed th select {
    border: 1px solid #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px;
    outline: none; }


/* ==========================================================================
   #FORM
   @mixin ========================================================================= */
input,
textarea {
    outline: none;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    font-size: 14px;
    font-family: inherit; }

textarea {
    height: 16rem; }

textarea.input--style-4 {
    line-height: 1.5;
    padding-top: 15px;
    padding-bottom: 15px;

    &::-webkit-input-placeholder {
        font-style: italic;
        opacity: .7; }

    &::-moz-placeholder {
        font-style: italic;
        opacity: .7; }

    &:-ms-input-placeholder {
        font-style: italic;
        opacity: .7; }

    &:-moz-placeholder {
        font-style: italic;
        opacity: .7; } }


.input--style-4 {
    line-height: 50px;
    background: #fafafa;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    color: #666;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease; }


.input--style-4::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #666; }


.input--style-4:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #666;
    opacity: 1; }


.input--style-4::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #666;
    opacity: 1; }


.input--style-4:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666; }


.input--style-4:-ms-input-placeholder {
    /* Microsoft Edge */
    color: #666; }


.label {
    font-size: 1.8rem;
    color: #555;
    // text-transform: capitalize
    display: block;
    margin-bottom: 5px;
    max-width: 65rem; }


.radio-container {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 16px;
    color: #666;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }


.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }


.radio-container input:checked ~ .checkmark {
    background-color: #e5e5e5; }


.radio-container input:checked ~ .checkmark:after {
    display: block; }


.radio-container .checkmark:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: $color-green; }


.checkmark {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e5e5e5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08); }


.checkmark:after {
    content: "";
    position: absolute;
    display: none; }


.input-group {
    position: relative;
    margin-bottom: 22px;
    display: block; }


.input-group-icon {
    position: relative; }


.input-icon {
    position: absolute;
    font-size: 18px;
    color: #999;
    right: 18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer; }


/* ==========================================================================
   #SELECT2
   @mixin ========================================================================= */
.select--no-search .select2-search {
    display: none !important; }


.rs-select2 .select2-container {
    width: 100% !important;
    outline: none;
    background: #fafafa;
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }


.rs-select2 .select2-container .select2-selection--single {
    outline: none;
    border: none;
    height: 50px;
    background: transparent; }


.rs-select2 .select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 50px;
    padding-left: 0;
    color: #555;
    font-size: 16px;
    font-family: inherit;
    padding-left: 22px;
    padding-right: 50px; }


.rs-select2 .select2-container .select2-selection--single .select2-selection__arrow {
    height: 50px;
    right: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }


.rs-select2 .select2-container .select2-selection--single .select2-selection__arrow b {
    display: none; }


.rs-select2 .select2-container .select2-selection--single .select2-selection__arrow:after {
    font-family: "Material-Design-Iconic-Font";
    content: '\f2f9';
    font-size: 24px;
    color: #999;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease; }


.rs-select2 .select2-container.select2-container--open .select2-selection--single .select2-selection__arrow::after {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg); }


.select2-container--open .select2-dropdown--below {
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #e0e0e0;
    margin-top: 5px;
    overflow: hidden; }


.select2-container--default .select2-results__option {
    padding-left: 22px; }


/* ==========================================================================
   #TITLE
   @mixin ========================================================================= */
.title {
    font-size: 24px;
    color: #525252;
    font-weight: 400;
    margin-bottom: 40px; }


/* ==========================================================================
   #CARD
   @mixin ========================================================================= */
.card {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #fff; }


.card-4 {
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15); }


.card-4 .card-body {
    padding: 57px 65px;
    padding-bottom: 65px; }


@media (max-width: 767px) {
    .card-4 .card-body {
        padding: 50px 40px; } }


.map-card {
    &.mb-4r {
        margin-bottom: 4rem; }

    &.mb-7r {
        margin-bottom: 7rem; }

    border: none;

    .title {
        font-size: 2.6rem;
        border-bottom: 1px solid #efefef;
        margin-bottom: 2rem;
        padding-bottom: 2rem;

        &.sub-title {
            font-weight: bold;
            padding-top: 50px;
            border-top: 5px solid #f7f7f7;
            font-size: 3.2rem;

            span {
                font-weight: normal;
                font-size: 1.8rem;
                display: block;
                opacity: .5; } } }

    .card-body {
        padding: 0; }

    .rs-select2 {
        width: 100%; } }


.select2-results__options li {
    font-size: 1.6rem; }

.red-mark {
    color: red;
    font-weight: bold; }
