.jury-list {
    // opacity: 0

    &__photo-slide {
        filter: grayscale(1);
        transition: all .3s;

        &.swiper-slide-active {
            filter: none;

            .jury-list__photo {
                width: 19rem;
                height: 19rem;
                box-shadow: 0 10px 30px rgba(#11ce8a, .5);
                transform: translateY(-2rem); } } }

    &__photo-slide {
        text-align: center;
        vertical-align: top; }

    &__photo-container {
        padding-top: 5rem;
        margin-bottom: 3rem; }

    &__photo-wrapper {
        height: 22rem; }

    &__photo {
        display: inline-block;
        overflow: hidden;
        border-radius: 50%;
        width: 15rem;
        height: 15rem;
        transition: all .3s; }


    &__fraction {
        text-align: center; }

    &__info-title {
        text-align: center;
        font-weight: bold;
        font-size: 2.8rem; }

    &__info-text {
        text-align: center; }

    &__info-next,
    &__info-prev {
        display: inline-block;
        width: 23px;
        transform: translateY(2px); }

    &__info-pagination {
        text-align: center;
        display: inline-block;
        margin: 0 20px; }

    .swiper-pagination-bullet {
        background-color: transparent;
        border: 1px solid #000;
        transition: all .3s;
        margin: 0 2px; }

    .swiper-pagination-bullet-active {
        background-color: $color-pink;
        border: none;
        width: 12px;
        height: 12px;
        transform: translateY(2px); }

    .swiper-button-disabled {
        opacity: .4; }

    &__nav {
        vertical-align: top;
        text-align: center; } }
