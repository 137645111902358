*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

html {
    font-size: 62.5%;

    @include respond (big-desktop) {
        font-size: 80%; }

    @include respond (phone) {
        font-size: 50%; } }

img {
    max-width: 100%; }

body {
    font-family: 'PT Sans Narrow', sans-serif;
    letter-spacing: 2px;
    line-height: 1.7;
    color: $color-text;
    font-size: 1.6rem;
    min-width: 300px;
    min-height: 100vh;

    * {
        color: $color-text; }

    &.home {
        background-color: $color-bg; } }

p {
    margin: 0 0 2rem; }

a {
    transition: all .3s; }



.social-links {
    &__home {
        margin: 1rem 6rem;

        @include respond (animation) {
            animation: homeElements 1s  ease-out .5s;
            animation-fill-mode: backwards; }

        @include respond (tab-port) {
            text-align: right;
            margin-left: 0;
            margin-right: 0;
            text-align: center; } }

    &__item {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px; }

    &__page {
        text-align: right;
        padding-top: 2rem;
        margin-right: 5rem;

        @include respond (animation) {
            animation: leftSwipe 1.5s  ease-out 1s;
            animation-fill-mode: backwards; }

        @include respond (tab-port) {
            margin-right: 0;
            margin-left: 0; }

        .social-links__item {
            @include respond (tab-port) {
                display: block; } } }


    a {
        color: $color-text;
        font-size: 2.5rem;

        i {
            transition: all .3s; }

        &:hover i,
        &:active i {
            color: $color-pink; } } }


.pri-podd {
    max-width: 14rem;

    img {
        width: 100%; } }



.logo {
    &__home {
        max-width: 55rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: all 5s;

        @include respond (animation) {
            animation: homeLogo 1.5s  ease-out .3s;
            animation-fill-mode: backwards;
            // transform: translate(-50%, -50%)
            transform: translate(-100%, -55%); }

        @include respond (laptop) {
            max-width: 35rem; }

        @include respond (tab-land) {
            transform: translate(-100%, -55%);
            transition: none; }

        @include respond (tab-port) {
            width: 30rem;
            max-width: 50%;
            position: relative;
            top: 0;
            margin-top: 8rem;
            left: 50%;
            transform: none;
            transform: translateX(-50%);

            transition: none !important; }


        img {} }

    &__sidebar {
        width: 26rem;
        max-width: 60%;
        margin: 7.5rem 0 0 6rem;

        @include respond (phone) {
            margin-top: 12rem; } } }


.main-menu {
    &__list {
        list-style: none;
        position: relative; }


    &__sidebar {
        max-width: 23rem; }

    &__home {
        text-align: right;
        position: absolute;
        right: 7.5rem;
        top: 50%;
        transform: translateY(-60%);
        z-index: 100;

        @include respond (animation) {
            animation: homeElements 1s  ease-out .5s;
            animation-fill-mode: backwards; }

        @include respond (tab-port) {
            position: relative;
            margin-top: 6rem;
            margin-bottom: 10rem;
            transform: none;
            right: 0;
            text-align: center; }

        .main-menu__link {
            font-size: 4.8rem;
            letter-spacing: 8px;

            @include respond (laptop) {
                font-size: 3.4rem;
                letter-spacing: 6px; }

            @include respond (tab-port) {
                font-size: 2.4rem; } } }

    &__item {
        position: relative;
        z-index: 1;

        &:hover {
            z-index: 2;

            .main-submenu {
                display: block;
                opacity: 1;

                a {
                    text-decoration: none; } } } }

    &__link {
        text-transform: lowercase;
        position: relative;
        padding-bottom: 1rem;
        display: block;
        display: inline-block;

        span {
            display: block;
            position: relative;
            z-index: 2;
            text-transform: lowercase; }

        &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 0;
            background-color: $color-green;
            z-index: 1;
            transform: translateX(0);
            transition: all .5s;
            opacity: 0; }

        &:hover {
            text-decoration: none;
            color: $color-text; } }


    &__active {
        &::before {
            // width: 85%
            width: 100%;
            // transform: translateX(-33%)
            transform: translateX(-6rem);
            opacity: 1; } }



    &__sidebar {
        margin: 7rem 0 0 7.5rem;

        .main-menu__link {
            font-size: 2.8rem;
            letter-spacing: 4px;
            padding-bottom: .5rem;
            margin-bottom: .5rem;

            &::before {
                right: auto;
                left: 0; } }

        .main-menu__active {
            &::before {
                transform: translateX(33%); } }

        @include respond (phone) {
            min-height: calc(100vh - 55rem); } } }


.main-submenu {
    position: absolute;
    width: 30rem;
    right: 0;
    display: none;
    opacity: 0;
    list-style: none;
    top: 0;
    transform: translateX(100%);

    @include respond (phone) {
        transform: none;
        position: static; }

    li {
        line-height: 1; }

    a {
        background-color: $color-pink;
        color: #fff;
        font-size: 2.4rem;
        padding: .4rem 1.5rem;
        position: relative;
        display: inline;
        line-height: 1.6;
        text-transform: lowercase;
        transition: all .3s;

        &:hover,
        &:active {
            background-color: $color-green; } } }



.main-menu__home {
    .main-submenu {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        @include respond (phone) {
            transform: translateX(0);
            width: 100%; }

        a {
            font-size: 2.4rem;
            letter-spacing: 4px;
            &::before {
                display: none; } } } }


.partners {
    &__logos {
        @include respond (animation) {
            animation: homeElements 1s  ease-out .5s;
            animation-fill-mode: backwards; }

        @include respond (tab-port) {
            text-align: center; } }


    &__logo {
        display: inline-block;
        vertical-align: top;
        margin-right: 3rem;

        img {
            height: 4.5rem;
            width: auto; } } }



.sidebar {

    width: 100%;

    &__wrap {
        position: relative;
        background-color: $color-bg;
        min-height: 100vh;
        position: fixed;
        width: 24%;
        left: 0;
        top: 0;
        z-index: 2;



        @include respond (laptop) {
            width: 33%; }

        @include respond (animation) {
            animation: sidebarElements .8s  ease-out .3s;
            animation-fill-mode: backwards; }

        @include respond (phone) {
            width: 90%;
            // display: none
            position: absolute;
            opacity: 0;
            z-index: -1;
            transition: all .5s; }

        &.opened {
            display: block;
            opacity: 1;
            z-index: 50;
            position: fixed;
            overflow: auto;
            height: 100%; } }

    .partners__logos {
        left: 7.5rem;
        bottom: 4rem;
        position: absolute;

        @include respond (phone) {
            position: relative;
            margin-top: 6rem;
            left: -1rem;
            // &.logosRelevant
            //     position: relative
            //     margin-top: 6rem
 } } }            //     left: -1rem




.right-side {

    &__header {
        text-align: right; }


    &__wrap {
        position: relative;
        margin-left: 24%;
        z-index: 1;



        @include respond (laptop) {
            margin-left: 33%; }

        @include respond (phone) {
            margin-left: 0; } }


    &__content {
        position: relative;
        margin: 0 15%;
        font-size: 2.4rem;
        padding-bottom: 10rem;

        @include respond (animation) {
            animation: sidebarElements .8s  ease-out .6s;
            animation-fill-mode: backwards; }

        @include respond (phone) {
            margin: 0 10%;
            padding-bottom: 0;
            min-height: calc(100vh - 300px);
            font-size: 1.8rem; }

        a {
            text-decoration: underline;
            transition: all .3s; } }


    &__title {
        font-size: 6rem;
        font-weight: bold;
        margin-bottom: 5rem;
        margin-top: 10rem; }

    &__footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding-top: 5.5rem;
        background: linear-gradient(rgba(#fff, 0), rgba(#fff, 1) 65%);
        // background: linear-gradient(rgba(#000, 0), rgba(#000, 1) 68%)

        @include respond (phone) {
            position: static;
            padding-top: 3rem; }



        .footer__menu {
            text-align: left;
            margin-left: 11%;
            padding-bottom: 1rem;

            @include respond (phone) {
 } } }                // padding-bottom: 10rem

    &__button {
        position: fixed;
        right: 0;
        bottom: 0;
        font-size: 3.6rem;
        padding: .5rem 5rem 2.5rem;
        line-height: 1.2;
        // background-color: #fff

        @include respond (animation) {
            animation: leftSwipe 1.5s  ease-out 1s;
            animation-fill-mode: backwards; }

        @include respond (phone) {
            position: relative;
            float: right; }


        &::before {
            content: "";
            display: block;
            width: 60%;
            height: 100%;
            background-color: $color-green;
            z-index: 1;
            position: absolute;
            right: 0;
            transition: all .3s; }

        span {
            position: relative;
            z-index: 2;
            text-transform: lowercase; }

        &:hover,
        &:active {
            text-decoration: none;

            &::before {
                width: 100%; } } } }


.footer {
    &__home {
        padding: 0 6rem;
        position: fixed;
        bottom: 3rem;
        width: 100%;

        @include respond (tab-port) {
            position: static; } }


    &__menu {
        text-align: right;
        list-style: none;

        @include respond (animation) {
            animation: homeElements 1s  ease-out .5s;
            animation-fill-mode: backwards; }

        @include respond (tab-port) {
            text-align: center;
            margin-top: 3rem; }

        &_link {
            display: inline-block;
            position: relative;

            &:hover,
            &:active {
                text-decoration: none;
                color: $color-pink; }

            &::before {
                content: "";
                position: absolute;
                width: 4px;
                height: 4px;
                background-color: $color-pink;
                left: -2rem;
                top: 1.1rem; } } } }


.calendar {
    .row {
        border-top: 1px solid #e6e6e6;
        padding: 1rem 0; }

    &__title {
        font-weight: bold; }

    &__month {
        opacity: .6; } }



ul.accordion {
    list-style: none;
    padding: 0;

    .inner {
        // padding-left: 1em
        overflow: hidden;
        display: none;
        padding: 4rem;
        opacity: .7;

        &.show {
 } }          // display: block



    li {
        margin: .5em 0;

        a.toggle {
            width: 100%;
            display: block;
            background: $color-bg;
            color: $color-text;
            padding: .75em 4rem;
            // border-radius: 0.15em
            transition: background .3s ease;
            text-decoration: none;

            &.active {
                background: $color-green; }

            &:hover {
                background: $color-green;
                text-decoration: none; } } } }



.nominations {
    font-size: 0;
    text-align: right;

    @include respond (tab-port) {
        text-align: left;
        border-top: 1px solid #e9e9e9; }


    &__item {
        font-size: 2.4rem;
        display: inline-block;
        width: 33%;

        @include respond (laptop) {
            font-size: 1.8rem; }

        @include respond (tab-port) {
            width: 49%; }

        a {
            display: block;
            position: relative;
            height: 16rem;
            text-transform: lowercase;
            line-height: 1;
            text-align: left;

            @include respond (laptop) {
                height: 12rem; }

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                right: 0;
                width: 1px;
                background-color: #e9e9e9;
                transition: all .3s;
                z-index: 1; }

            &::before {
                content: "";
                position: absolute;
                height: 1px;
                width: 100%;
                bottom: 0;
                left: 0;
                background-color: #e9e9e9;
                transition: all .3s;
                z-index: 2; }

            &:hover,
            &:active {
                &::after {
                    width: 100%; }

                &::before {
                    height: 5px;
                    background-color: $color-green; } } }




        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #7f7f7f;
            z-index: 3; }

        &_1 {
            @include respond (tab-port) {
                width: 100%;
                a::after {
                    width: 0; } } }

        &_2 {}

        &_3 {
            a::after {
                width: 0; } }

        &_4 {
            width: 49%;
            a::before {
                bottom: auto;
                top: -1px;

                @include respond (tab-port) {
                    bottom: 0;
                    top: auto;
                    height: 0; } } }

        &_5 {
            width: 50%;
            a::before {
                bottom: auto;
                top: -1px; }

            a::after {
                width: 0; } } } }



.map {
    width: 100%;
    height: 35rem;
    margin-bottom: 3rem;
    // -webkit-filter: grayscale(100%)
    // filter: grayscale(100%)
    // opacity: .7

    [class*="ymaps-2"][class*="-ground-pane"] {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
        /* Firefox 3.5+ */
        -webkit-filter: grayscale(100%);
        /* Chrome 19+ & Safari 6+ */ } }



.form-check-inline {
    @include respond (tab-port) {
        display: block; } }

//
// .container
//     width: 155rem
//     padding: 0 10rem
//
//     @include respond (laptop)
//         width: 117rem
//         padding-right: 15px
//         padding-left: 15px
//
//     @include respond (tab-land)
//         width: 100%
//         padding-right: 15px
//         padding-left: 15px
//
// body
//     background-image: url(../img/inner-page-bg.png)
//     background-position: top center
//     background-repeat: no-repeat
//     background-size: 100% auto
//
//     @include respond (laptop)
//         background-size: auto 110vh
//
//     @include respond (phone)
//         background-position: top right -30rem
//
// .home
//     background-image: url(../img/home-bg.png)
